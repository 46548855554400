import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Pagination, Row, Table, Modal, ButtonGroup } from 'react-bootstrap';
import { deleteRecipient,updateRecipientResponse, removeRecipientResponse } from '../services/api';
import PreviewMessage from './PreviewMessage';

function RecipientViewTable({ viewingRecipientData, customVariables, handleEdit, getTableViewHeader, setRefreshRecipients, assignedMessageContent }) {
    const [selectedRecipientIds, setSelectedRecipientIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalRecipient, setModalRecipient] = useState(null);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 25;
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = viewingRecipientData?.slice(indexOfFirstRow, indexOfLastRow) || [];
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [previewRecipientId, setPreviewRecipientId] = useState(null);

    const handleShowPreview = (recipientId) => {
        setPreviewRecipientId(recipientId);
        setShowPreviewModal(true);
    };

    const handleClosePreview = () => {
        setShowPreviewModal(false);
        setPreviewRecipientId(null);
    };

    

const handleClosePreviewModal = () => setShowPreviewModal(false);

    // Calculate total pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(viewingRecipientData.length / rowsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
        setSelectedRecipientIds([]);
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = ('0' + date.getDate()).slice(-2); // Adds leading zero if needed
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adds leading zero, months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const toggleRowSelection = (index) => {
        setSelectedRecipientIds(prev => {
            let updated = [...prev];
            const i = updated.indexOf(index);
            if (i > -1) {
                updated.splice(i, 1);
            } else {
                updated.push(index);
            }
            return updated;
        });
    };

    const handleDeleteRecipients = (recipients) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete ${recipients.length} recipient(s)?`);

        if (isConfirmed) {
            recipients.forEach(recipientId => {
                try {
                    deleteRecipient(recipientId);
                } catch (error) {
                    console.error("Error deleting recipient", error);
                }
            });
            setSelectedRecipientIds([]);
            setRefreshRecipients(true);
        }
    };

    const handleRecipientResponse = async (recipientId, status) => {
        try {
            let response;
    
            if (status === false) {
                // Call the removeRecipientResponse API when status is false
                response = await removeRecipientResponse(recipientId);
            } else {
                // Call the updateRecipientResponse API when status is true
                response = await updateRecipientResponse(recipientId, status);
            }
    
            console.log('Response updated:', response.data);
    
            // Update local state for immediate UI feedback
            setSelectedRecipientIds((prevRecipients) => 
                prevRecipients.map((recipient) => 
                    recipient._id === recipientId
                        ? { ...recipient, customVariables: recipient.customVariables.map(cv => 
                            cv.name === 'response' ? { ...cv, value: status.toString() } : cv
                          )}
                        : recipient
                )
            );
    
            // Trigger a refresh to ensure data consistency
            setRefreshRecipients(true);
        } catch (error) {
            console.error('Error updating recipient response:', error);
        }
    };
    
    
    

    // Open modal and set recipient data for custom variables
    const handleShowModal = (recipient) => {
        setModalRecipient(recipient);
        setShowModal(true);
    };

    // Close modal
    const handleCloseModal = () => {
        setShowModal(false);
        setModalRecipient(null);
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Row className='mb-3'>
                        <Card.Title>
                            <Button variant="secondary" className={'mx-3 ' + ((selectedRecipientIds.length === 0) ? "disabled" : "")} onClick={() => setSelectedRecipientIds([])}>
                                <i className='bi bi-clear'></i> Clear Selection
                            </Button>
                            <Button variant="danger" className={(selectedRecipientIds.length === 0) ? "disabled" : undefined} onClick={() => handleDeleteRecipients(selectedRecipientIds)}>
                                <i className="bi bi-trash"></i> Delete
                            </Button>
                        </Card.Title>
                    </Row>
                    <Row className='mt-3'>
                        <Table bordered hover fluid responsive className='my-2'>
                            <thead>{getTableViewHeader()}</thead>
                            <tbody>
                                {currentRows?.map((recipient, index) => (
                                    <tr key={index} className={selectedRecipientIds.includes(recipient._id) ? "table-active bg-primary" : undefined} onClick={() => toggleRowSelection(recipient._id)}>
                                        <td>{recipient.fullName}</td>
                                        <td>{recipient.firstName}</td>
                                        <td>{recipient.lastName}</td>
                                        <td>{recipient.companyName}</td>
                                        <td>{recipient.addressLine1}</td>
                                        <td>{recipient.addressLine2}</td>
                                        <td>{recipient.addressLine3}</td>
                                        <td>{recipient.cityCounty}</td>
                                        <td>{recipient.postcode}</td>
                                        {customVariables.map(field => (
                                            <td key={field}>
                                                
                                                 
                                                    <span>{recipient.customVariables.find(cv => cv.name === field)?.value || 'N/A'}</span>
                                                
                                            </td>
                                        ))}

                                        <td>{formatDate(recipient.submittedDate)}</td>
                                        <td>{recipient.statusV1}</td>

                                        {/* Actions column with Edit and View Variables buttons */}
                                        <td>
                                        <ButtonGroup aria-label="Actions">
                                            <Button variant="outline-primary" size="sm" onClick={() => handleEdit(recipient._id)}>
                                                <i className="bi bi-pencil"></i> Edit
                                            </Button>
                                            <Button
    variant={
        recipient.customVariables.find(cv => cv.name === 'response' && cv.value === 'true')
            ? "outline-danger"
            : "outline-warning"
    }
    size="sm"
    onClick={() => {
        const hasResponse = recipient.customVariables.find(cv => cv.name === 'response' && cv.value === 'true');
        handleRecipientResponse(
            recipient._id,
            hasResponse ? false : true // Determine whether to add or remove response
        );
    }}
>
    <i className="bi bi-envelope-paper"></i>
    {recipient.customVariables.find(cv => cv.name === 'response' && cv.value === 'true')
        ? "Remove Response"
        : "Record Response"}
</Button>



                                            <Button variant="outline-info" size="sm" onClick={() => handleShowModal(recipient)}>
    <i className="bi bi-sliders"></i> View Variables ({recipient.customVariables.length})
</Button>

                                            <Button variant="outline-success" size="sm" onClick={() => handleShowPreview(recipient._id)}>
                                                <i className="bi bi-eye"></i> Preview Message
                                            </Button>
                                        </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Row>
                    {pageNumbers.length > 1 &&
                        <Row>
                            <Pagination className='d-flex justify-content-center'>
                                {pageNumbers.map(number => (
                                    <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                                        {number}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </Row>
                    }
                </Card.Body>
            </Card>
                
           {/* Modal for displaying PreviewMessage component */}
           <Modal show={showPreviewModal} onHide={handleClosePreview} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Message Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {previewRecipientId && (
                        <PreviewMessage recipientId={previewRecipientId} />
                    )}
                </Modal.Body>
            </Modal>

            {/* Modal for viewing custom variables */}
            {modalRecipient && (
               <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
               <Modal.Header closeButton>
                   <Modal.Title>Custom Variables and Metadata for {modalRecipient.fullName}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   {modalRecipient.customVariables.length > 0 ? (
                       <div className="table-responsive">
                           <Table striped bordered hover size="sm">
                               <thead>
                                   <tr>
                                       <th>Variable</th>
                                       <th>Value</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   {modalRecipient.customVariables.map((cv, i) => (
                                       <tr key={i}>
                                           <td>{cv.name}</td>
                                           <td>{cv.value || 'N/A'}</td>
                                       </tr>
                                   ))}
                               </tbody>
                           </Table>
                       </div>
                   ) : (
                       <p>No custom variables available for this recipient.</p>
                   )}
               </Modal.Body>
               <Modal.Footer>
                   <Button variant="secondary" onClick={handleCloseModal}>
                       Close
                   </Button>
               </Modal.Footer>
           </Modal>
           
           
            )}
        </>
    );
}

export default RecipientViewTable;
